/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import "../../static/css/bootstrap.min.css"
import "../../static/css/aos.css"
import "../../static/css/owl.theme.green.css"
import "../../static/css/demo.css"
import "../../static/css/style.css"
import "../../static/css/ourstyle.css"
import "../../static/css/tania.css"
import Header from "./header"
import Footer from "./footer"
import preloader from "../images/pre-loader.gif"

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    if (typeof window !== "undefined") {
        // eslint-disable-next-line global-require
        // require("smooth-scroll")('a[href*="#"]')
    }

    return (
        <div>
            <div className="preloader" id="preloader">
                <div className="preloader">
                    <img className="spinner" src={preloader} alt=""/>
                </div>
            </div>

            <div id="main-wrapper">
                <Header siteTitle={data.site.siteMetadata.title}/>
                <div className="page-wrapper">
                    <div className="container-fluid">
                        <main>{children}</main>
                    </div>
                    <a className="bt-top btn btn-circle btn-lg btn-info" href="#top"><i className="ti-arrow-up"></i></a>
                </div>
                <Footer/>
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
