import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/fundacion_black.svg"


const Header = ({siteTitle}) => (
    <div className="topbar">
        <div className="header1 po-relative">
            <div className="container top">
                <div className="round-social light">
                <a href="https://www.facebook.com/Fundaci%C3%B3n-Pedro-P%C3%A9rez-Garrido-105864918360391" className="link"><i className="fa fa-facebook"/></a>
                            <a href="https://www.youtube.com/channel/UC8eA5jDVy25l423mFuapjqQ" className="link"><i className="fa fa-youtube-play"/></a>
                            <a href="https://twitter.com/perez_fundacion" className="link"><i className="fa fa-twitter"/></a>
                            <a href="https://www.instagram.com/perez_fundacion/" className="link"><i className="fa fa-instagram"/></a>
                            
                </div>
            </div>
            <div className="container">
                {/* Header 1 code */}
                <nav className="navbar navbar-expand-lg h1-nav">
                    <Link to="/" className="navbar-brand"><img src={logo} alt="wrapkit"/></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mheader1"
                            aria-controls="mheader1" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="ti-menu"/>
                    </button>
                    <div className="collapse navbar-collapse hover-dropdown" id="mheader1">
                        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                            <li className="nav-item dropdown mega-dropdown active">
                                <a className="nav-link dropdown-toggle" href="#"
                                   id="h6-mega-dropdown" data-toggle="dropdown"
                                   aria-haspopup="true" aria-expanded="false">
                                    Nosotros <i className="fa fa-angle-down m-l-5"/>
                                </a>
                                <div className="dropdown-menu half b-none font-14 animated fadeInUp"
                                     aria-labelledby="h6-mega-dropdown">
                                    <div className="row">
                                        <div className="col-lg-6 inside-bg hidden-md-down">
                                            <div className="bg-img"
                                                 style={{backgroundImage: "url(../images/nosotrosm.jpg)"}}>
                                                <h3 className="text-white font-light">Nosotros</h3></div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 pr-lg-0 pl-lg-0">
                                            <ul className="list-style-none">
                                                <li><Link to="/quienFue">¿Quién fue Pedro Pérez Garrido?</Link></li>
                                                <li><Link to="/mensaje/">Mensaje de Manuel S. Pérez Alavez</Link></li>
                                                <li><Link to="/#quienes">¿Quiénes somos?</Link></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown mega-dropdown"><a className="nav-link dropdown-toggle"
                                                                               href="#"
                                                                               id="h6-mega-dropdown1"
                                                                               data-toggle="dropdown"
                                                                               aria-haspopup="true"
                                                                               aria-expanded="false">
                                Nuestra labor
                                <i className="fa fa-angle-down m-l-5"/>
                            </a>
                                <div className="dropdown-menu half b-none font-14 animated fadeInUp"
                                     aria-labelledby="h6-mega-dropdown1">
                                    <div className="row">
                                        <div className="col-lg-6 inside-bg hidden-md-down">
                                            <div className="bg-img"
                                                 style={{backgroundImage: "url(../images/laborm.jpg)"}}>
                                                <h3 className="text-white font-light">Nuestra labor</h3>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 pr-lg-0 pl-lg-0">
                                            <ul className="list-style-none">
                                                <li><br></br></li>
                                                <li><Link to="/#sector-turistico">Estudios del turismo</Link>
                                                </li>
                                                <li><Link to="/#investigacion-innovacion">Desarrollo regional</Link>
                                                </li>
                                                <li>
                                                    <Link to="/#posesion-tierras">Adopción de mejores prácticas sobre posesión de la tierra</Link>
                                                </li>
                                                <li><Link to="/#cadenas-valor">Creación de cadenas de
                                                    valor</Link>
                                                </li>
                                                <li><Link to="/#economicos-sociales">Evaluación de proyectos</Link></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </li>
                           

                            <li className="nav-item dropdown "><a className="nav-link dropdown-toggle"
                                                                               href="#"
                                                                               id="h6-dropdown1"
                                                                               data-toggle="dropdown"
                                                                               aria-haspopup="true"
                                                                               aria-expanded="false">
                                Eventos
                                <i className="fa fa-angle-down m-l-5"/>
                            </a>
                                <div className="dropdown-menu large"
                                     aria-labelledby="h6-dropdown1" > 
                                    <Link to="/concurso/" className="nav-link">Concurso de fotografía Chetumal</Link>

                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="/#donativos" className="nav-link">Donativos y obra social</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="/aportaciones/" className="nav-link">
                                    Aportaciones
                                </Link>
                            </li>
                            <li className="nav-item dropdown mega-dropdown"><Link to="/contacto/" className="nav-link">
                                Contacto
                            </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
                {/* End Header 1 code */}
            </div>
        </div>
    </div>

)

Header.propTypes = {
    siteTitle: PropTypes.string
}

Header.defaultProps = {
    siteTitle: ``
}

export default Header
