import React from "react"
import logo from "../images/fundacion_black.svg"

const Footer = () => {
    return (

        <div className="footer4 spacer b-t">
            <div className="container">
                <div className="row">
                    <div className="col-lg-2 col-md-6 m-b-30 text-center">
                        {/*<img src={logo} alt="wrapkit" style={{width:"30%"}}/>*/}
                        <img src={logo} alt="wrapkit"/>
                    </div>
                    <div className="col-lg-6 col-md-6 m-b-30">
                        <p>La fundación Pedro Pérez Garrido es una organización comprometida a enaltecer la calidad de
                            vida y desarrollo de la Zona Sur de Quintana Roo, a través de una acción social transversal
                            y la profundización de temas estratégicos para la economía y empleo, persiguiendo recobrar
                            la dignificación del sur, y que todos los othonenses y chetumaleños puedan desarrollarse a
                            plenitud en su terruño.</p>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <h6 className="m-b-20">Para mayor información sobre nosotros visítenos en:</h6>
                        <div className="round-social light">
                            <a href="https://www.facebook.com/Fundaci%C3%B3n-Pedro-P%C3%A9rez-Garrido-105864918360391" className="link"><i className="fa fa-facebook"/></a>
                            <a href="https://www.youtube.com/channel/UC8eA5jDVy25l423mFuapjqQ" className="link"><i className="fa fa-youtube-play"/></a>
                            <a href="https://twitter.com/perez_fundacion" className="link"><i className="fa fa-twitter"/></a>
                            <a href="https://www.instagram.com/perez_fundacion/" className="link"><i className="fa fa-instagram"/></a>
                            {/* <a href="#" className="link"><i className="fa fa-whatsapp"/></a> */}
                        </div>
                        <h6 style={{fontSize: "14px"}}>
                            <span>Avenida Álvaro Obregón, Centro, Chetumal, Q.R. </span><br/>
                            <a href="mailto: contacto@fundacionperezgarrido.com"> contacto@fundacionperezgarrido.com</a><br/>
                            
                        </h6>

                    </div>

                </div>
                {/* <div className="f4-bottom-bar">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex font-14">
                  <div className="m-t-10 m-b-10 copyright">All Rights Reserved by WrapPixel.</div>
                  <div className="links ml-auto m-t-10 m-b-10">
                    <a href="#" className="p-10 p-l-0">Terms of Use</a>
                    <a href="#" className="p-10">Legal Disclaimer</a>
                    <a href="#" className="p-10">Privacy Policy</a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </div>
        </div>


    )
}


export default Footer
